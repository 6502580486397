<template>
  <div>
    <div class="container has-text-centered">
      <div class="is-size-7 has-text-grey my-2">
        <div v-if="challenge && challenge.position && challenge.position.shownOnMap">
        {{
          getLang() !== 'de' ?
          t(`Go into the circle to access the ${$store.state.uiMods.task.toLowerCase()}.`)
          :
          ""
        }}
        </div>
        <div v-if="challenge && challenge.position && !challenge.position.shownOnMap">
        {{ t(`Location for this ${$store.state.uiMods.task.toLowerCase()} is hidden.`) }}
        </div>
        {{ t('It may take up to 3 mins to update location.') }}
      </div>
    </div>
    <div v-if="userLocation && userLocation.lat" class="mt-1">
      <Map
      class="task-map-modifier"
      :lat="userLocation.lat"
      :lon="userLocation.lon"
      :challenge="challenge"
      :challenges="stageDetails.challenges"
      :teamPositions="adventureTeam.teamPositions"
      :zoom="$store.state.taskMapZoom"
      :key="`taskMap-${key}`"
      :mapHeight="mapHeight"
      />
    </div>
    <div class="container has-text-centered">
      <div class="buttons has-addons is-centered mt-2 mb-0">
        <a
        v-if="$store.state.userLocation"
        @click="openGoogleMapsDirections()"
        class="button has-text-weight-semibold is-small mr-2"
        v-bind:class="{'is-loading': loading}"
        >
          <i class="fas fa-mobile-alt mr-2"></i>
          Location not updating?
        </a>
        <a
        @click="getLocation()"
        class="button has-text-weight-semibold is-small is-primary"
        v-bind:class="{'is-loading': loading}"
        >
          <i class="fas fa-map-marker-alt mr-2"></i>
          {{ userLocation ? 'Update location' : 'Get location' }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map'

export default {
  name: 'TaskMap',
  props: ['mapHeight', 'challenge'],
  components: {
    Map
  },
  data () {
    return {
      key: 0,
      loading: false
    }
  },
  methods: {
    getLocation() {
      this.loading = true
      if (
        this.challenge &&
        this.challenge.type === 'direction' &&
        this.$store.state.userAllowsLocationDetection === false
      ) {
        this.$store.commit('getUserLocationMutation', {
          userAllowsLocationDetection: false,
          lat: this.challenge.answers[0].lat,
          lon: this.challenge.answers[0].lon
        })
      } else {
        this.$store.dispatch('getUserLocationAction', this.t)
      }
      setTimeout(() => { this.loading = false }, 2500)
      this.key += 1
    },
    openGoogleMapsDirections (lat, lon) {
      alert(this.t("Once your location is shown correctly on your maps app, come back to this page & press 'Update location'.", false))
      window.open(`http://maps.apple.com/?q=`, '_self')
    }
  },
  computed: {
    currentStage() {
      return this.$store.state.currentStage
    },
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    stageDetails() {
      if(this.adventureTeam){
        if(this.adventureTeam.stage === this.currentStage){
          return this.adventureTeam.stageDetails
        } else {
          return this.adventureTeam.stageDetailsHistory[this.currentStage-1]
        }
      } else {
        return null
      }
    },
    userLocation () {
      return this.$store.state.userLocation
    },
    userLocationExpiryTimestamp () {
      return this.$store.state.userLocationExpiryTimestamp
    }
  },
  watch: {
    userLocation() {
      this.key += 1
    }
  },
  mounted () {
    this.getLocation()
  }
}
</script>

<style>

</style>
